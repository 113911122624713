<template>
  <v-container>
    <SectionTitle title="Produtos">
      <template slot="action-buttons">
        <v-btn
          class="mr-2"
          x-large
          color="primary"
          icon
          @click="() => expandItems = !expandItems"
        >
          <v-icon size="20">
            {{ expandItems ? 'fas fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt' }}
          </v-icon>
        </v-btn>
      </template>
    </SectionTitle>

    <v-row dense>
      <v-col
        cols="12"
        lg="2"
      >
        <v-text-field
          v-model="filter.product_code"
          label="Código do produto"
          placeholder="MB00000"
          dense
          outlined
          rounded
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model="filter.product_name"
          label="Nome do produto"
          placeholder="Digite o nome do produto"
          dense
          outlined
          rounded
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-select
          v-model="filter.factor_id"
          label="Tabela fator"
          :items="factor.items"
          item-text="cost"
          item-value="id"
          dense
          outlined
          rounded
          hide-details
          @focus.once="getFactors"
        >
          <template v-slot:no-data>
            <div
              v-if="factor.isLoading"
              class="pa-4"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <h6
              v-else
              class="text-subtitle-2 text-center"
            >
              Nenhum fator encontrado
            </h6>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-select
          v-model="filter.product_status"
          label="Status do produto"
          :items="[{title: 'Ativo', value: true}, {title: 'Inativo', value: false}]"
          item-text="title"
          item-value="value"
          dense
          outlined
          rounded
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-text-field
          v-model="filter.vendor_code"
          label="Código do fornecedor"
          dense
          outlined
          rounded
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-select
          v-model="filter.vendor_status"
          label="Status do fornecedor"
          :items="vendorStatus"
          item-text="title"
          item-value="id"
          dense
          outlined
          rounded
          hide-details
        >
          <template v-slot:item="{ item }">
            <v-list-item-avatar size="20">
              <v-icon
                small
                :color="item.color"
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        lg="2"
      >
        <v-select
          v-model="filter.vendor_choosed"
          label="Escolhido"
          :items="[{title: 'Sim', value: 'yes'}, {title: 'Não', value: 'no'}]"
          item-text="title"
          item-value="value"
          dense
          outlined
          rounded
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-text-field
          v-model="filter.vendor_observation"
          label="Observação"
          dense
          outlined
          rounded
          hide-details
        />
      </v-col>
    </v-row>
    <v-row
      align="end"
      dense
      class="mt-5"
    >
      <v-col
        cols="12"
        lg="4"
      >
        <v-row dense>
          <v-col cols="12">
            <div class="d-flex">
              <v-icon
                small
                color="primary"
                left
              >
                fas fa-calendar-alt
              </v-icon>
              <h6 class="caption">
                Data de atualização
              </h6>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-text-field
              v-model="filter.updated_at_from"
              label="De:"
              placeholder="00/00/0000"
              rounded
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-text-field
              v-model="filter.updated_at_to"
              label="Até:"
              placeholder="00/00/0000"
              rounded
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-row dense>
          <v-col cols="12">
            <div class="d-flex">
              <v-icon
                small
                color="primary"
                left
              >
                fas fa-hand-holding-usd
              </v-icon>
              <h6 class="caption">
                Custo fixo
              </h6>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="filter.fixed_cost_from"
              v-mask="['#,##', '##,##', '###,##']"
              label="De:"
              placeholder="R$00,00"
              rounded
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="filter.fixed_cost_to"
              v-mask="['#,##', '##,##', '###,##']"
              label="Até:"
              placeholder="R$00,00"
              rounded
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
            class="d-flex"
          >
            <v-btn
              rounded
              outlined
              color="primary"
              class="mr-2"
              @click="clearFilter"
            >
              Limpar filtro
            </v-btn>
            <v-btn
              rounded
              color="primary"
              @click="filterVendorProducts"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <MassActionToolbar
      :selected="selectedItems"
      :current-component="'product-vendors-mass-action'"
      :class="selectedItems.length ? 'mt-10' :'' "
      @clearSelected="clearSelected"
    />
    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      class="grey--text text--darken-2 lign-headers"
      :class="!selectedItems.length ? 'mt-10' :'mt-0' "
      style="position: relative"
      loading-text="Carregando dados, por favor aguarde"
      no-results-text="Nenhum resultado encontrado"
      no-data-text="Nenhum dado encontrado"
      hide-default-footer
      checkbox-color="primary"
      show-select
      fixed-header
    >
      <template v-slot:top>
        <v-alert
          :value="successEditing"
          dense
          text
          style="position: absolute; top: -35px"
          type="success"
          transition="slide-x-transition"
          class="font-weight-bold"
        >
          {{ alertMessage }}
        </v-alert>
      </template>

      <template v-slot:item="{ item, index }">
        <tr>
          <td>
            <v-checkbox
              v-model="selectedItems"
              :value="item"
              style="margin: 0px; padding: 0px"
              hide-details
            />
          </td>
          <td style="width: 50px">
            <v-icon
              v-if="item.product.vendor_selected == vendor.id"
              color="primary"
            >
              fas fa-check-circle
            </v-icon>
          </td>
          <td class="py-2">
            <v-avatar
              :size="expandItems ? 130 : 30"
              rounded
              class="elevation-2"
            >
              <v-img
                v-if="item.product_image"
                :src="$store.state.imagePath + item.product_image.src"
              />
              <v-icon
                v-else
                small
                color="primary"
              >
                fas fa-box
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <div>
              <u class="primary--text">
                <a
                  class="primary--text"
                  @click.prevent="navigateToProduct(item.product.id)"
                >
                  {{ item.product.product_name }}
                </a>
              </u>
            </div>
            <div>{{ item.product.product_reference }}</div>
          </td>
          <td class="text-center">
            <v-item-group
              v-model="item.vendor_status"
              mandatory
              @change="v => setStatus(v, item.vendor_id, item.product.id, index)"
            >
              <v-container class="d-flex justify-center">
                <v-item
                  v-for="(status, sIdx) in vendorStatus"
                  :key="sIdx"
                  v-slot="{ active, toggle }"
                  :disabled="loadingStatus"
                  :value="status.id"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        :color="active ? status.color : 'grey lighten-3'"
                        class="d-flex align-center justify-center mx-1"
                        height="30"
                        width="30"
                        v-bind="attrs"
                        dark
                        v-on="on"
                        @click="toggle"
                      >
                        <v-progress-circular
                          v-if="loadingStatus"
                          color="primary"
                          indeterminate
                          size="20"
                        />
                        <v-icon
                          v-else
                          small
                          :color="active ? 'white' : 'grey lighten-1'"
                        >
                          {{ status.icon }}
                        </v-icon>
                      </v-card>
                    </template>
                    <span>{{ status.title }}</span>
                  </v-tooltip>
                </v-item>
              </v-container>
            </v-item-group>
          </td>
          <td>
            <span>
              {{ item.vendor_code }}
              <v-menu
                max-width="350"
                :value="openedCodeEditMenu === index"
                offset-y
                :close-on-click="false"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ attrs }">
                  <v-icon
                    v-bind="attrs"
                    color="primary"
                    class="edit-icon"
                    :ripple="false"
                    size="15"
                    @click="openedCodeEditMenu = index"
                  >
                    fas fa-edit
                  </v-icon>
                </template>
                <v-card>
                  <v-card-text class="d-flex align-center">
                    <v-text-field
                      :value="item.vendor_code"
                      autofocus
                      placeholder="Código do fornecedor"
                      solo
                      flat
                      dense
                      hide-details
                      @input="v => defineCode(v)"
                    />
                    <v-btn
                      class="mr-2"
                      rounded
                      small
                      outlined
                      color="primary"
                      @click="openedCodeEditMenu = null"
                    >Cancelar</v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      :loading="loadingCodeEdit == index"
                      small
                      :ripple="false"
                      @click="updateCode(item.cost_tables[0].view_id, index)"
                    >salvar</v-btn>
                  </v-card-text>
                </v-card>
              </v-menu>
            </span>
          </td>
          <td
            v-if="item.cost_tables[0].is_fixed == 1"
            class="text-center"
          >
            <span>
              {{ parseFloat(item.cost_tables[0].rows[0].cost.toFixed(2)).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}

              <v-menu
                max-width="350"
                :value="openedCostEditMenu === index"
                offset-y
                :close-on-click="false"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ attrs }">
                  <v-icon
                    v-bind="attrs"
                    color="primary"
                    class="edit-icon"
                    :ripple="false"
                    size="15"
                    @click="openedCostEditMenu = index"
                  >
                    fas fa-edit
                  </v-icon>
                </template>
                <v-card>
                  <v-card-text class="d-flex align-center">
                    <v-text-field
                      v-mask="['#,##', '##,##']"
                      :value="item.cost_tables[0].rows[0].cost.toFixed(2).toLocaleString('pt-BR')"
                      autofocus
                      prefix="R$"
                      placeholder="R$0,00"
                      solo
                      flat
                      dense
                      hide-details
                      @input="v => defineCost(v)"
                    />
                    <v-btn
                      class="mr-2"
                      rounded
                      small
                      outlined
                      color="primary"
                      @click="openedCostEditMenu = null"
                    >Cancelar</v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      :loading="loadingCostEdit == index"
                      small
                      :ripple="false"
                      @click="updateCost(item.cost_tables[0].rows[0].id, index)"
                    >salvar</v-btn>
                  </v-card-text>
                </v-card>
              </v-menu>
            </span>
          </td>
          <td
            v-else
            class="text-center"
          >
            <v-icon
              small
              color="primary"
            >
              fas fa-table
            </v-icon>
          </td>
          <td style="max-width: 300px">
            <div
              style="max-height: 70px;"
              class="overflow-auto"
            >
              {{ item.vendor_description }}
            </div>
          </td>
          <td style="max-width: 100px;">
            <v-menu
              :value="openedDescriptionEditMenu === index"
              offset-y
              :close-on-click="false"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ attrs }">
                <v-icon
                  v-bind="attrs"
                  color="primary"
                  class="edit-icon"
                  :ripple="false"
                  size="15"
                  @click="openedDescriptionEditMenu = index"
                >
                  fas fa-edit
                </v-icon>
              </template>
              <v-card>
                <v-card-text>
                  <v-textarea
                    :value="item.vendor_description"
                    autofocus
                    placeholder="Digite a observação para o fornecedor"
                    solo
                    flat
                    dense
                    rows="3"
                    style="width: 400px"
                    hide-details
                    @input="v => defineDescription(v)"
                  />
                  <v-divider />
                </v-card-text>
                <v-card-actions class="pt-1">
                  <v-spacer />
                  <v-btn
                    class="mr-2"
                    rounded
                    small
                    outlined
                    color="primary"
                    @click="openedDescriptionEditMenu = null"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    rounded
                    color="primary"
                    :loading="loadingDescriptionEdit == index"
                    small
                    :ripple="false"
                    @click="updateDescription(item.cost_tables[0].view_id, index)"
                  >
                    salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </td>
          <td class="text-right">
            {{ item.updated_at.slice(0, 10).split('-').reverse().join('/') }}
          </td>
          <td class="text-right">
            <v-btn
              :disabled="item.product.vendor_selected == vendor.id"
              small
              color="primary"
              outlined
              rounded
              @click="updateProductSelectedVendor(item, index)"
            >
              selecionar
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import MassActionToolbar from '../../../MassActionToolbar.vue';

export default {
  components: {
    SectionTitle: () => import("@/components/SectionTitle"),
    MassActionToolbar,
  },
  props: {
    vendor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedItems:[],
    products:null,
    filter: {},
    expandItems: null,
    openedDescriptionEditMenu: null,
    definedDescription: null,
    loadingDescriptionEdit: null,
    openedCodeEditMenu: null,
    loadingCodeEdit: null,
    openedCostEditMenu: null,
    loadingCostEdit: null,
    successEditing: false,
    loadingStatus: false,
    alertMessage: '',
    factor: {
      items: [],
      isLoading: true
    },
    items: [],
    headers: [
      { text: '', align: 'center' },
      { text: 'Imagem', value: 'src', align: 'center' },
      { text: 'Produto', value: 'product_name', align: 'center' },
      { text: 'Status', value: 'vendor_status', align: 'center' },
      { text: 'Cód. Fornecedor', value: 'vendor_code', align: 'start' },
      { text: 'Custo', value: 'cost', align: 'center' },
      { text: 'Observação', value: 'vendor_description', align: 'center' },
      { text: '', align: 'center' },
      { text: 'Data atlz.', value: 'updated_at', align: 'center' },
    ],
    vendorStatus: [
      { id: 1, title: "Ok", icon: "fas fa-check-circle", color: 'success' },
      { id: 2, title: "Consultar", icon: "fas fa-search", color: 'blue' },
      { id: 3, title: "Sem estoque", icon: "fas fa-box-open", color: 'error' },
      { id: 4, title: "Fora de linha", icon: "fas fa-times-circle", color: 'grey' },
    ],
    definedCost: 0.0
  }),
  created() {
    this.getProducts()
    console.log(this.$route.params)
  },
  methods: {
    filterVendorProducts(){
      this.$api.post('products/vendor/filter', {...this.filter, vendor_id: this.vendor.id})
        .then(res => {
          this.items = this.normalizeProducts(res.data.data)
        })
        .catch(err => {
          console.error(err);
        })
    },
    clearSelected() {
      this.getProducts()
      this.selectedItems = []
    },
    clearFilter(){
      this.filter = {}
      this.getProducts()
    },
    getProducts(){
      this.$api
        .post("product_vendors",{id:this.vendor.id})
        .then((res) => {
          this.items = res.data.data;
          this.selectedItems = [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFactors(){
      this.$api.get('product_factors')
        .then(res => {
          this.factor.items = res.data.data
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.factor.isLoading = false
        })
    },
    getFilteredProducts(obj) {
      this.$api
        .post("products/filter", obj)
        .then((res) => {
          this.dataTable.items = res.data.data;
        })
        .catch(() => {
          this.dataTable.items = [];
        })
        .finally(() => {
          this.dataTable.isLoading = false;
        });
    },
    toggleAlt(){
      this.openedCostEditMenu = null
    },
    updateCost(id, idx){
      this.loadingCostEdit = idx
      this.$api.patch('update_vendor_finance_cost', { table_id: id, cost: this.definedCost, view_id: this.items[idx].cost_tables[0].view_id })
        .then(() => {
          this.openedCostEditMenu = null

          this.items[idx].cost_tables[0].rows[0].cost = this.definedCost

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          this.alertMessage = 'Custo fixo alterado com sucesso'
          this.successEditing = true

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCostEdit = null
        })
    },
    navigateToProduct(productId) {
      const url = `${window.location.origin}/stock/products/${productId}`;
      window.open(url, "_blank");
    },
    updateDescription(id, idx){
      this.loadingDescriptionEdit = idx
      this.$api.patch('update_vendor_description', { view_vendor_id: id, description: this.definedDescription })
        .then(() => {
          this.openedDescriptionEditMenu = null

          this.items[idx].vendor_description = this.definedDescription

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          this.alertMessage = 'Observação do fornecedor alterada com sucesso'
          this.successEditing = true

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingDescriptionEdit = null
        })
    },
    updateCode(id, idx){
      this.loadingCodeEdit = idx
      this.$api.patch('update_vendor_finance_code', { view_vendor_id: id, code: this.definedCode })
        .then(() => {
          this.openedCodeEditMenu = null

          this.items[idx].vendor_code = this.definedCode

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          this.alertMessage = 'Código do fornecedor alterado com sucesso'
          this.successEditing = true

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCodeEdit = null
        })
    },
    updateProductSelectedVendor(item, idx){
      this.$api.patch('products/update-selected-vendor', { product_id: item.product.id, vendor_id: this.vendor.id, view_id: item.cost_tables[0].view_id })
        .then(() => {
          item.product.vendor_selected = this.vendor.id

          this.alertMessage = 'Fornecedor selecionado com sucesso'
          this.successEditing = true

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
    },
    defineDescription(value){
      this.definedDescription = value
    },
    defineCost(value){
      if(value.includes(',')){
        let newvalue = parseFloat(value.replace(',', '.'))
        this.definedCost = newvalue
      }else{
        this.definedCost = parseFloat(value)
      }
    },
    defineCode(value){
      this.definedCode = value
    },
    normalizeProducts(data){
      data.map(val => {
        val.product_image = val.product_images[0]
        val.cost_tables = val.product_vendors[0].cost_tables
        val.vendor_status = val.product_vendors[0].vendor_status
        val.vendor_code = val.product_vendors[0].vendor_code
        val.product = {
          product_name: val.product_name,
          product_reference: val.product_reference,
          vendor_selected: val.vendor_selected,
        }
      })

      return data
    },
    setStatus(value, vendor_id, product_id, idx){
      this.loadingStatus = true
      this.$api.patch('products/vendor-status', { vendor_id: vendor_id, product_id: product_id, status: value })
        .then(() => {
          this.alertMessage = 'Status atualizado com sucesso'
          this.successEditing = true

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingStatus = false
        })
    }
  },
}
</script>

<style>
.align-headers .v-data-table-header th {
  text-align: center;
  vertical-align: middle;
}
</style>